import React from 'react'
import styles from './ContentPattern.module.css'

interface Children {
	children: React.ReactNode
}

export default function ContentPattern(props: Children) {
	return (
		<div className={styles.container}>
			<div className={styles.content}>{props.children}</div>
		</div>
	)
}
