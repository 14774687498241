export default function (money: number, percent: number) {
	const comissionPercent: Readonly<number> = percent

	const percentFull = ((money * comissionPercent) / 100).toFixed(2)
	// const percentLeft = ((money * comissionPercent) / 100).toString().split('.')[0]
	// const percentRight = parseFloat('0.' + ((money * comissionPercent) / 100).toString().split('.')[1]).toFixed(2)

	const priceFull = (+money + +percentFull).toFixed(2)
	const priceLeft = +priceFull.toString().split('.')[0]
	const priceRight = parseFloat('0.' + priceFull.toString().split('.')[1])
		.toFixed(2)
		.toString()
		.split('.')[1]

	return {
		percentFull: +percentFull,
		priceFull: +priceFull,
		priceLeft: +priceLeft,
		priceRight: +priceRight,
	}
}
