import React from 'react'
import styles from './Shop.module.css'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Location from '../../mobx/Location'
import { observer } from 'mobx-react-lite'
import { useTranslate } from '../hooks/useTranslate'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			maxWidth: 800,
			// backgroundColor: theme.palette.background.paper,
		},
	})
)

interface Categorys {
	retval: number
	retdesc: string
	queryId: string
	category: [
		{
			id: string
			name: string
			cnt: string
		}
	]
}

const CategoriesComponent = observer(() => {
	const [categories, setCategories] = React.useState<Categorys>()
	const classes = useStyles()

	const translate = useTranslate({
		title: { en: 'Catalog', ru: 'Каталог' },
		loading: { en: 'Loading categories', ru: 'Загрузка категорий' },
		retdesc: { en: 'There are no categories', ru: 'Нет ни одной категории' },
		countProducts: { en: 'Count of products', ru: 'Количество товаров' },
	})

	React.useEffect(() => {
		fetch(`https://api.digiseller.ru/api/categories?lang=${Location.getLanguage()}&seller_id=654077`, {
			headers: {
				Accept: 'application/json',
			},
		})
			.then(response => response.json())
			.then(response => {
				setCategories({ ...response })
				console.log(response)
			})
	}, [])

	if (!categories) return <>{translate.loading}...</>
	if (categories.retdesc) return <>{translate.retdesc}.</>

	return (
		<>
			<p className={styles.pathName}>{translate.title}</p>
			<List component="nav" className={classes.root} aria-label="contacts">
				{categories?.category.map(cat => (
					<Paper className={styles.shadow} key={cat.id} style={{ marginBottom: 10 }} elevation={0}>
						<ListItem button style={{ backgroundColor: '#ffffff' }} component={Link} to={`/shop/${cat.id}`}>
							<ListItemIcon>
								{/* <LocalAtmIcon fontSize="large" style={{ color: '#5bbf97' }} /> */}
								<img style={{ height: 60, marginRight: 14 }} src={`https://graph.digiseller.ru/img.ashx?idn=${cat.id}`} />
							</ListItemIcon>
							<ListItemText
								primary={<span className={styles.listPrimary}>{cat.name}</span>}
								// secondary={
								// 	<span className={styles.listSecondary}>
								// 		{translate.countProducts}: <span className={styles.colored}>{cat.cnt}</span>
								// 	</span>
								// }
							/>
						</ListItem>
					</Paper>
				))}
			</List>
		</>
	)
})

export default CategoriesComponent
