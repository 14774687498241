import React from 'react'
import styles from './Sales.module.css'
import { Product } from '../ProductComponent'
import { useTranslate } from '../../hooks/useTranslate'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

export default function Sales({ product }: Product) {
	console.log(JSON.stringify(product.discounts))
	if (product.collection === 'unit') console.log(JSON.stringify(product.units.discounts))

	const translate = useTranslate({
		captionUnit: { en: 'Discounts on the quantity of purchased goods', ru: 'Скидки от количества приобретаемого товара' },
		startingFromUnit: { en: 'When buying', ru: 'При покупке от' },
		perUnit: { en: 'Price per', ru: 'Цена за' },
		caption: { en: 'Discount for regular customers', ru: 'Cкидка постоянным покупателям' },
		startingFrom: { en: 'Purchase amount', ru: 'Сумма покупок от' },
		discount: { en: 'Discount', ru: 'Cкидка' },
	})

	return (
		<>
			{product.collection === 'unit' && product.units.discounts ? (
				<>
					<p>{translate.captionUnit}:</p>
					<TableContainer component={Paper} elevation={2}>
						<Table>
							{/* <caption>{translate.captionUnit}</caption> */}
							<TableHead>
								<TableRow>
									<TableCell style={{ fontWeight: 600 }} align="center">
										{translate.startingFromUnit}
									</TableCell>
									<TableCell style={{ fontWeight: 600, wordBreak: 'initial' }} align="center">
										{translate.discount}
									</TableCell>
									<TableCell style={{ fontWeight: 600 }} align="center">
										{translate.perUnit} {product.units.desc}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{product.units.discounts.map(discount => (
									<TableRow key={discount.desc + discount.price}>
										<TableCell component="th" scope="row" align="center">
											{discount.desc}
										</TableCell>
										<TableCell align="center">{discount.percent}%</TableCell>
										<TableCell align="center">{discount.price} ₽</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			) : null}
			{product.discounts ? (
				<>
					<p>{translate.caption}:</p>
					<TableContainer component={Paper} elevation={1}>
						<Table>
							{/* <caption>{translate.caption}</caption> */}
							<TableHead>
								<TableRow>
									<TableCell style={{ fontWeight: 600 }} align="center">
										{translate.startingFrom}
									</TableCell>
									<TableCell style={{ fontWeight: 600, wordBreak: 'initial' }} align="center">
										{translate.discount}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{product.discounts.map(discount => (
									<TableRow key={discount.summa + '' + discount.percent}>
										<TableCell component="th" scope="row" align="center">
											{discount.summa} ₽
										</TableCell>
										<TableCell align="center">{discount.percent}%</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			) : null}
		</>
	)
}
