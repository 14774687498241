import React from 'react'
import styles from './BottomMenu.module.css'
import { Paper } from '@material-ui/core'
import { useTranslate } from '../hooks/useTranslate'
import Location from '../../mobx/Location'
import shopStyles from '../Shop/Shop.module.css'
import ContentPattern from '../ContentPattern/ContentPattern'
import { RouteComponentProps } from 'react-router-dom'

interface Info {
	header: string
	info: string
	queryId: string
}

interface Response {
	currency: string
	view: string
	rows: number
	logo_img: string
	main_view: string
	menu_purchases: boolean
	menu_reviews: boolean
	menu_contacts: boolean
	menu: string[]
	bottom_menu: {
		id: number
		name_ru: string
		name_en: string
	}[]
	online_chat: number
	imgsize_firstpage: number
	imgsize_listpage: number
	imgsize_infopage: number
	imgsize_category: number
	show_feedbacks: number
	pay_target_self: number
	lang: string
	buy_url: string
}

export default function BottomMenu({ match }: RouteComponentProps<{ id: string }>) {
	const [aboutInfo, setAboutInfo] = React.useState<Info>()
	const translate = useTranslate({
		loading: { en: 'Loading info', ru: 'Загрузка информации' },
	})

	React.useEffect(() => {
		fetch(`https://api.digiseller.ru/api/shop-settings?seller_id=654077`)
			.then(response => response.json())
			.then((responseList: Response) => {
				responseList.bottom_menu.forEach(listItem => {
					if (listItem.name_en.replace(/ /g, '-').toLowerCase() === match.params.id) {
						fetch(
							`https://shop.digiseller.ru/xml/shop_menu.asp?transp=cors&format=json&lang=${Location.getLanguage()}&seller_id=654077&menu=bottom-menu-${
								listItem.id
							}`
						)
							.then(response => response.json())
							.then(response => {
								setAboutInfo(response)
							})
					}
				})
			})
	}, [match.params.id])

	if (!aboutInfo) return <ContentPattern>{translate.loading}...</ContentPattern>

	return (
		<ContentPattern>
			<p className={shopStyles.pathName}>{aboutInfo.header}</p>
			<Paper className={styles.container} elevation={0}>
				<div dangerouslySetInnerHTML={{ __html: aboutInfo.info }} />
			</Paper>
		</ContentPattern>
	)
}
