import React from 'react'
import styles from './Calculator.module.css'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import { useTranslate } from '../../hooks/useTranslate'
import { Product } from '../ProductComponent'

import queryString from 'query-string'
import Location from '../../../mobx/Location'
import getPrice from './getPrice'

// import payment_methods from './paymentMethods'

interface Calc {
	retval: number
	retdesc: string
	data: {
		price: number
		count: number
		amount: number
		currency: string
		commission: number
		free_pay: unknown
		sale_info: {
			common_base_price: number
			sale_percent: number
		}
	}
}

export default function Unit({ product }: Product) {
	const translate = useTranslate({
		buy: { en: 'Buy', ru: 'Купить' },
		way: { en: 'Payment method', ru: 'Способ оплаты' },
		minPrice: { en: 'Minimum you can buy', ru: 'Минимум вы можете купить' },
		maxPrice: { en: 'Maximum you can buy', ru: 'Максимум вы можете купить' },
		onlyK: { en: 'Only multiples of 1000', ru: 'Только количество кратное 1000' },
		commission: { en: 'commission', ru: 'комиссия' },
	})

	const [paymentMethods, setPaymentMethods] = React.useState(0)
	const [currency, setCurrency] = React.useState(0)
	const [money, setMoney] = React.useState(0)
	const [gold, setGold] = React.useState(0)
	const [options, setOptions] = React.useState({})
	const [buy, setBuy] = React.useState(true)

	const [payment_methods, setMethods] = React.useState<CalcMethods[]>()
	const price = payment_methods && getPrice(money, payment_methods[paymentMethods].percent)

	React.useEffect(() => {
		fetch(`/calculator_methods.json?${new Date().getTime()}`, { cache: 'no-cache' })
			.then(response => response.json())
			.then((response: { methods: CalcMethods[] }) => {
				setMethods(response.methods)
				const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${response.methods[paymentMethods].currency}&unit_cnt=${product.prices_unit.unit_cnt_min}`

				fetch(uri)
					.then(response => response.json())
					.then((response: Calc) => {
						setGold(product.prices_unit.unit_cnt_desc === 'к' ? ~~response.data.count * 1000 : ~~response.data.count)
						setMoney(response.data.amount)
					})

				product?.options &&
					product.options.forEach(option => {
						setOptions(prev => {
							return { ...prev, [option.name]: '' }
						})
					})
			})
	}, [])

	const handleChangeOptions = (event: React.ChangeEvent<{ value: string; name: string }>) => {
		// setOptions(event.target.value as number)
		setOptions(prev => {
			return { ...prev, [event.target.name]: event.target.value }
		})
	}

	const handleChangePaymentMethods = (event: React.ChangeEvent<{ value: unknown }>) => {
		setCurrency(0)
		const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
			payment_methods && payment_methods[event.target.value as number].currency
		}&unit_cnt=${product.prices_unit.unit_cnt_desc === 'к' ? gold / 1000 : gold}`

		setBuy(false)
		fetch(uri)
			.then(response => response.json())
			.then((response: Calc) => {
				setMoney(response.data.amount)
				setGold(product.prices_unit.unit_cnt_desc === 'к' ? ~~response.data.count * 1000 : ~~response.data.count)
				setBuy(true)
			})
		setPaymentMethods(event.target.value as number)
	}

	const handleChangeCurrency = (event: React.ChangeEvent<{ value: unknown }>) => {
		const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
			payment_methods && payment_methods[paymentMethods].currency
		}&unit_cnt=${product.prices_unit.unit_cnt_desc === 'к' ? gold / 1000 : gold}`

		setBuy(false)
		fetch(uri)
			.then(response => response.json())
			.then((response: Calc) => {
				setMoney(response.data.amount)
				setGold(product.prices_unit.unit_cnt_desc === 'к' ? ~~response.data.count * 1000 : ~~response.data.count)
				setBuy(true)
			})
		setCurrency(event.target.value as number)
	}

	const handleChangeMoney = async (event: React.ChangeEvent<{ value: unknown }>) => {
		const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
			payment_methods && payment_methods[paymentMethods].currency
		}&amount=${event.target.value}`

		setBuy(false)
		fetch(uri)
			.then(response => response.json())
			.then((response: Calc) => {
				setGold(product.prices_unit.unit_cnt_desc === 'к' ? ~~response.data.count * 1000 : ~~response.data.count)
				setBuy(true)
			})
		setMoney(event.target.value as number)
	}

	const handleChangeGold = (event: React.ChangeEvent<{ value: unknown }>) => {
		const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
			payment_methods && payment_methods[paymentMethods].currency
		}&unit_cnt=${product.prices_unit.unit_cnt_desc === 'к' ? (event.target.value as number) / 1000 : event.target.value}`

		setBuy(false)
		fetch(uri)
			.then(response => response.json())
			.then((response: Calc) => {
				setMoney(response.data.amount)
				setBuy(true)
			})
		// setGold(event.target.value as number)
		setGold(~~(event.target.value as number))
	}

	if (!payment_methods) return <></>
	if (!price) return <></>

	return (
		<div>
			<TextField
				id="standard-number"
				label={
					payment_methods[paymentMethods].currency === 'WMR'
						? `₽`
						: payment_methods[paymentMethods].currency === 'WMZ'
						? '$'
						: payment_methods[paymentMethods].currency === 'WME' && '€'
				}
				type="number"
				InputLabelProps={{
					shrink: true,
				}}
				value={money}
				onChange={handleChangeMoney}
				className={styles.textboxNumber}
				style={{ marginRight: '2%' }}
				helperText={
					price.percentFull > 0 && <span style={{ whiteSpace: 'nowrap' }}>{`+${price.percentFull}₽ ${translate.commission}`}</span>
				}
			/>
			<TextField
				error={
					gold < product.prices_unit.unit_cnt_min ||
					(product.prices_unit.unit_cnt_desc === 'к' && gold < product.prices_unit.unit_cnt_min * 1000) ||
					(product.prices_unit.unit_cnt_desc === 'к' && gold % 1000 !== 0)
				}
				id="standard-number"
				label={product.prices_unit.unit_name}
				type="number"
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					step: product.prices_unit.unit_cnt_desc === 'к' ? 1000 : 1,
				}}
				value={gold}
				onChange={handleChangeGold}
				className={styles.textboxNumber}
				helperText={
					gold < product.prices_unit.unit_cnt_min ||
					(product.prices_unit.unit_cnt_desc === 'к' && gold < product.prices_unit.unit_cnt_min * 1000)
						? `${translate.minPrice} ${product.prices_unit.unit_cnt_min}${product.prices_unit.unit_cnt_desc} ${product.prices_unit.unit_name}`
						: '' || (product.prices_unit.unit_cnt_desc === 'к' && gold % 1000 !== 0)
						? translate.onlyK
						: ''
				}
			/>
			<br />
			<br />
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<FormControl
					style={{
						marginRight: product.payment_methods[paymentMethods].currencies.length > 1 ? '2%' : 0,
						width: '100%',
					}}
				>
					<InputLabel htmlFor="payment_methods">{translate.way}</InputLabel>
					<Select
						native
						value={paymentMethods}
						onChange={handleChangePaymentMethods}
						inputProps={{
							id: 'payment_methods',
						}}
					>
						{payment_methods.map((method, index) => {
							if (method.active)
								return (
									<option key={method.name[Location.getLanguage()]} value={index}>
										{method.name[Location.getLanguage()]}
									</option>
								)
						})}
					</Select>
				</FormControl>
				{/* {product.payment_methods[paymentMethods].currencies.length > 1 ? (
					<FormControl style={{ width: 80 }}>
						<InputLabel htmlFor="currency"></InputLabel>
						<Select
							native
							value={currency}
							onChange={handleChangeCurrency}
							inputProps={{
								id: 'currency',
							}}
						>
							{product.payment_methods[paymentMethods].currencies.map((currency, index) => {
								return (
									<option key={currency.code} value={index}>
										{currency.currency}
									</option>
								)
							})}
						</Select>
					</FormControl>
				) : null} */}
			</div>
			<br />
			<Divider />
			<br />
			{product?.options && product.options.length ? (
				<>
					{product.options.map((option, index) => {
						if (option.type === 'text') {
							return (
								<div key={option.name}>
									<TextField
										name={option.name}
										label={
											<span style={{ letterSpacing: 0 }}>
												<Tooltip disableFocusListener disableTouchListener title={<span style={{ fontSize: 11 }}>{option.comment}</span>}>
													<span style={{ padding: '0 4px', borderRadius: '50%', color: '#ffffff', background: '#3f51b5' }}>?</span>
												</Tooltip>{' '}
												{option.label}
											</span>
										}
										onChange={handleChangeOptions}
										type="text"
										InputLabelProps={{
											shrink: true,
										}}
										// value={money}
										// onChange={handleChangeMoney}
										style={{ width: '100%' }}
									/>
									{index !== product.options.length - 1 ? (
										<>
											<br />
											<br />
										</>
									) : null}
								</div>
							)
						}
					})}
				</>
			) : null}
			<br />
			<br />
			{/* <Button
				onClick={() => {
					console.log(options)

					fetch('https://api.digiseller.ru/api/purchases/options', {
						method: 'POST',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							product_id: product.id,
							options: Object.entries(options).map(([key, value]) => {
								return { id: key, value: { text: value } }
							}),
							unit_cnt: gold,
							lang: Location.getLanguage(),
							// ip: Location.getIP(),
						}),
					})
						.then(response => response.json())
						.then(response => {
							window.open(
								`https://www.digiseller.market/asp2/pay_wm.asp?id_d=${product.id}&id_po=${response.id_po}&failpage=${
									window.location
								}&lang=${Location.getLanguage()}&curr=${product.payment_methods[paymentMethods].currencies[currency].code}`,
								'_self'
							)
						})
				}}
				disableElevation
				variant="contained"
				color="primary"
				className={styles.buyBtn}
				disabled={Object.values(options).some(e => !(e as string).trim())}
			>
				{translate.buy}
			</Button> */}
			{Object.values(options).some(e => !(e as string).trim()) ||
			!buy ||
			gold < product.prices_unit.unit_cnt_min ||
			(product.prices_unit.unit_cnt_desc === 'к' && gold < product.prices_unit.unit_cnt_min * 1000) ||
			(product.prices_unit.unit_cnt_desc === 'к' && gold % 1000 !== 0) ? (
				<>
					<Button disabled disableElevation variant="contained" color="primary" className={styles.buyBtn}>
						{translate.buy}
					</Button>
				</>
			) : (
				<>
					{/* WEBMONEY */}
					{(payment_methods[paymentMethods].code === 'wmr' ||
						payment_methods[paymentMethods].code === 'wmp' ||
						payment_methods[paymentMethods].code === 'wmz' ||
						payment_methods[paymentMethods].code === 'wme') && (
						<form method="POST" target="_blank" acceptCharset="windows-1251" action="https://merchant.webmoney.ru/lmi/payment.asp">
							<input type="hidden" name="LMI_PAYMENT_AMOUNT" value={price.priceFull} />
							<input type="hidden" name="LMI_PAYEE_PURSE" value={payment_methods[paymentMethods].wallet} />
							<input
								type="hidden"
								name="LMI_PAYMENT_DESC"
								value={`${product.name}; ${gold}; ${
									Object.entries(options).length &&
									Object.entries(options)
										.map(([key, value], index) => {
											return `${value};`
										})
										.join(' ')
								}`}
							/>
							<input type="hidden" name="DOMAIN_MODE" value="2" />
							<input type="hidden" name="LMI_PAYMENT_NO" value={product.id} />
							<Button type="submit" disableElevation variant="contained" color="primary" className={styles.buyBtn}>
								{translate.buy}
							</Button>
						</form>
					)}

					{/* YOOMONEY */}
					{(payment_methods[paymentMethods].code === 'yoo' ||
						payment_methods[paymentMethods].code === 'yoo_card' ||
						payment_methods[paymentMethods].code === 'yoo_phones') && (
						<form method="POST" target="_blank" action="https://yoomoney.ru/quickpay/confirm.xml">
							<input type="hidden" name="receiver" value={payment_methods[paymentMethods].wallet} />
							<input type="hidden" name="formcomment" value={product.name} />
							<input type="hidden" name="short-dest" value={product.name} />
							<input type="hidden" name="label" value={product.name} /> <input type="hidden" name="quickpay-form" value="donate" />
							<input type="hidden" name="targets" value={product.name} />
							<input type="hidden" name="sum" value={price.priceFull} data-type="number" />
							<input
								type="hidden"
								name="comment"
								value={`${product.name}; ${gold}; ${
									Object.entries(options).length &&
									Object.entries(options)
										.map(([key, value], index) => {
											return `${value};`
										})
										.join(' ')
								}`}
							/>
							<input type="hidden" name="need-fio" value="false" /> <input type="hidden" name="need-email" value="false" />
							<input type="hidden" name="need-phone" value="false" /> <input type="hidden" name="need-address" value="false" />
							<input
								type="hidden"
								name="paymentType"
								value={
									payment_methods[paymentMethods].code === 'yoo'
										? 'PC'
										: payment_methods[paymentMethods].code === 'yoo_card'
										? 'AC'
										: payment_methods[paymentMethods].code === 'yoo_phones'
										? 'MC'
										: ''
								}
							/>
							<Button type="submit" disableElevation variant="contained" color="primary" className={styles.buyBtn}>
								{translate.buy}
							</Button>
						</form>
					)}

					{(payment_methods[paymentMethods].code === 'qiwi' || payment_methods[paymentMethods].code === 'qiwi_card') && (
						<Button
							href={`https://qiwi.com/payment/form/99
							?extra%5B%27account%27%5D=${payment_methods[paymentMethods].wallet}
							&amountInteger=${price.priceLeft}
							&amountFraction=${price.priceRight}
							&extra%5B%27comment%27%5D=${`${product.name}; ${gold}; ${
								Object.entries(options).length &&
								Object.entries(options)
									.map(([key, value], index) => {
										return `${value};`
									})
									.join(' ')
							}`}
							&currency=643
							&blocked[0]=account
							`}
							target="_blank"
							disableElevation
							variant="contained"
							color="primary"
							className={styles.buyBtn}
						>
							{translate.buy}
						</Button>
					)}
				</>
			)}
		</div>
	)
}
