import React from 'react'
import { makeAutoObservable } from 'mobx'

const EN = 'en-US'
const RU = 'ru-RU'

class Location {
	private language?: 'en-US' | 'ru-RU'
	private browserLanguage: string = localStorage.getItem('lang') || window.navigator.language
	// private ipAddress?: string

	constructor() {
		makeAutoObservable(this)

		if (this.browserLanguage === 'ru' || this.browserLanguage === 'ru-RU') {
			this.language = RU
			localStorage.setItem('lang', RU)
		} else {
			this.language = EN
			localStorage.setItem('lang', EN)
		}
	}

	// async setCountryCode() {
	// 	if (!this.countryCode) {
	// 		await fetch('https://api.ipinfodb.com/v3/ip-city/?key=25864308b6a77fd90f8bf04b3021a48c1f2fb302a676dd3809054bc1b07f5b42&format=json')
	// 			.then(response => response.json())
	// 			.then(response => {
	// 				if (response.countryCode === 'UA' || response.countryCode === 'RU' || response.countryCode === 'BY') {
	// 					this.countryCode = RU
	// 				} else {
	// 					this.countryCode = EN
	// 				}
	// 				this.ipAddress = response.ipAddress
	// 			})
	// 	}
	// }

	getLanguage() {
		if (this.browserLanguage === 'ru' || this.browserLanguage === 'ru-RU') {
			return RU
		} else {
			return EN
		}
	}

	// getIP() {
	// 	return this.ipAddress
	// }

	changeLanguage(lang: 'en-US' | 'ru-RU') {
		this.language = lang
		localStorage.setItem('lang', lang)
	}
}

export default new Location()
