import React from 'react'
import styles from './Reviews.module.css'
import shopStyles from '../Shop/Shop.module.css'
import TablePagination from '@material-ui/core/TablePagination'
import { useTranslate } from '../hooks/useTranslate'
import Location from '../../mobx/Location'
import NotFound from '../NotFound/NotFound'
import Guarantee from './Guarantee'
import ContentPattern from '../ContentPattern/ContentPattern'

import { Switch, Route, RouteComponentProps, useHistory } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { Paper } from '@material-ui/core'

import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'

interface Params {
	page: string
}

interface Response {
	queryId: string
	error?: string
	review: [
		{
			good: number
			comment: string
			date: string
			info: string
			name: string
			type: 'good' | 'bad'
		}
	]
	totalBad: string
	totalGood: string
	totalItems: string
	totalPages: string
}

function Comments({ review }: Response) {
	const translate = useTranslate({
		admin: { en: 'Admin comment', ru: 'Комментарий администратора' },
	})

	return (
		<>
			<List>
				{review.map(comment => (
					<Paper className={shopStyles.shadow} key={comment.date} style={{ marginBottom: 10 }} elevation={0}>
						<ListItem style={{ backgroundColor: '#ffffff' }}>
							<ListItemIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: -10 }}>
								{comment.type === 'good' ? (
									<ThumbUpAltOutlinedIcon style={{ color: '#2ecc71' }} />
								) : (
									<ThumbDownAltOutlinedIcon style={{ color: '#e74c3c' }} />
								)}
							</ListItemIcon>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<ListItemText primary={<span dangerouslySetInnerHTML={{ __html: comment.info }} />} /> {/*secondary={comment.date} */}
								{comment.comment.trim() ? (
									<ListItemText
										style={{}}
										primary={
											<div style={{ backgroundColor: '#f5f5f5', borderRadius: 12, padding: 12 }}>
												<span style={{ color: 'rgb(231, 76, 60)' }}>{translate.admin}: </span>
												<span dangerouslySetInnerHTML={{ __html: comment.comment }} />
											</div>
										}
									/>
								) : null}
							</div>
						</ListItem>
					</Paper>
				))}
			</List>
		</>
	)
}

function Container({ match }: RouteComponentProps<Params>) {
	const [response, setResponse] = React.useState<Response>()
	const [page, setPage] = React.useState(match.params.page ? +match.params.page - 1 : 0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const history = useHistory()

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage)

		fetch(
			`https://shop.digiseller.ru/xml/shop_reviews.asp?transp=cors&format=json&lang=${Location.getLanguage()}&seller_id=654077&page=${
				newPage + 1
			}&rows=${+rowsPerPage}`
		)
			.then(response => response.json())
			.then(response => {
				setResponse(response)
				history.push('/reviews/' + (newPage + 1))
				window.scrollTo({ top: document.body.scrollHeight, behavior: 'auto' })
			})
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		// setPage(0)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const translate = useTranslate({
		onPage: { en: 'On page', ru: 'На странице' },
	})

	React.useEffect(() => {
		fetch(
			`https://shop.digiseller.ru/xml/shop_reviews.asp?transp=cors&format=json&lang=${Location.getLanguage()}&seller_id=654077&page=${
				page + 1
			}&rows=${+rowsPerPage}`
		)
			.then(response => response.json())
			.then(response => {
				setResponse(response)
			})
	}, [])

	if (!response) return <>Loading...</>
	if (!response.review || response.error) return <NotFound />

	return (
		<div style={{ width: '100%' }}>
			<Comments {...response} />
			<TablePagination
				component="div"
				count={+response.totalItems}
				page={page}
				onChangePage={handleChangePage}
				rowsPerPage={rowsPerPage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				labelRowsPerPage={translate.onPage + ':'}
				rowsPerPageOptions={[]}
			/>
		</div>
	)
}

export default function Reviews() {
	const translate = useTranslate({
		reviews: { en: 'Reviews', ru: 'Отзывы' },
	})

	return (
		<ContentPattern>
			<p className={shopStyles.pathName}>{translate.reviews}</p>
			<Guarantee />
			<Switch>
				<Route exact path="/reviews" component={Container} />
				<Route path="/reviews/:page" component={Container} />
			</Switch>
		</ContentPattern>
	)
}
