import React from 'react'
// import styles from './Shop.module.css'
import { Switch, Route } from 'react-router-dom'
import CategoriesRoute from './CategoriesRoute'
import ProductsRoute from './ProductsRoute'
import ContentPattern from '../ContentPattern/ContentPattern'

export default function Shop() {
	return (
		<ContentPattern>
			<Switch>
				<Route exact path="/shop" component={CategoriesRoute} />
				<Route path="/shop/:category" component={ProductsRoute} />
			</Switch>
		</ContentPattern>
	)
}
