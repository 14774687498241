import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Location from '../mobx/Location'
import { useHistory } from 'react-router-dom'
import en from '../images/en.png'
import ru from '../images/ru.png'

export default function ChangeLanguageButton() {
	return (
		<ButtonGroup variant="text">
			<Button
				disabled={Location.getLanguage() === 'ru-RU'}
				onClick={() => {
					Location.changeLanguage('ru-RU')
					window.location.reload()
				}}
			>
				<img src={ru} style={{ filter: Location.getLanguage() === 'ru-RU' ? 'grayscale(90%)' : '' }} />
			</Button>
			<Button
				disabled={Location.getLanguage() === 'en-US'}
				onClick={() => {
					Location.changeLanguage('en-US')
					window.location.reload()
				}}
			>
				<img src={en} style={{ filter: Location.getLanguage() === 'en-US' ? 'grayscale(90%)' : '' }} />
			</Button>
		</ButtonGroup>
	)
}
