import React from 'react'
import styles from './Navbar.module.css'
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MinNavbar from './MinNavbar/MinNavbar'
import { useTranslate } from '../hooks/useTranslate'
import ChangeLanguageButton from '../ChangeLanguageButton'
import Button from '@material-ui/core/Button'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'

interface StyledTabsProps {
	value: number
	onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

const StyledTabs = withStyles({
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > span': {
			maxWidth: 40,
			width: '100%',
			backgroundColor: '#091021',
		},
	},
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

interface StyledTabProps {
	label: string
}

const StyledTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: 'none',
			color: '#091021',
			fontWeight: theme.typography.fontWeightBold,
			fontSize: theme.typography.pxToRem(15),
			'&:focus': {
				opacity: 1,
			},
		},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme: Theme) => ({
	padding: {
		padding: theme.spacing(0),
	},
	menu: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#f5f5f5',
		paddingTop: 20,
	},
}))

const paths = ['/', '/shop', '/reviews', '/guarantee']

const useStylesMin = makeStyles((theme: Theme) =>
	createStyles({
		menuButton: {
			marginRight: theme.spacing(2),
			color: '#ffffff',
		},
		title: {
			flexGrow: 1,
		},
	})
)

export default function Navbar() {
	const classes = useStyles()
	const classesMin = useStylesMin()
	const history = useHistory()
	const [location] = history.location.pathname.slice(1).split('/')
	const pathIndex = paths.indexOf(`/${location}`)
	const [value, setValue] = React.useState(pathIndex > -1 ? pathIndex : 0)
	const [reserved, setReserved] = React.useState(false)

	const [openMinNav, setOpenMinNav] = React.useState(false)

	const translate = useTranslate({
		home: { en: 'Home', ru: 'Главная' },
		shop: { en: 'Store', ru: 'Магазин' },
		reviews: { en: 'Reviews', ru: 'Отзывы' },
		guarantee: { en: 'Guarantee', ru: 'Гарантия' },
		purchases: { en: 'My purchases', ru: 'Мои покупки' },
		// faq: { en: 'FAQ', ru: 'FAQ' },
		// suppliers: { en: 'Suppliers', ru: 'Поставщикам' },
	})

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
		setTimeout(() => {
			// if (paths[newValue] === '/reviews') {
			// 	history.push('/reviews/1')
			// 	return
			// }
			history.push(paths[newValue])
		}, 250)
	}

	React.useEffect(() => {
		fetch('/payments.json')
			.then(response => response.json())
			.then((response: any) => {
				if (response['zapasnaya_oplata']) setReserved(true)
			})
	}, [])

	return (
		<>
			<div className={styles.adaptiveMin} style={{ flexGrow: 1 }}>
				<MinNavbar openMinNav={openMinNav} setOpenMinNav={setOpenMinNav} paths={paths} />
				<AppBar position="static">
					<Toolbar style={{ background: '#4c566f' }}>
						<IconButton
							edge="start"
							className={classesMin.menuButton}
							onClick={() => setOpenMinNav(true)}
							aria-label="menu"
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
			</div>

			<div className={styles.adaptive} style={{ flexGrow: 1 }}>
				<div className={classes.menu}>
					<StyledTabs value={value} onChange={handleChange}>
						<StyledTab label={translate.home} />
						<StyledTab label={translate.shop} />
						<StyledTab label={translate.reviews} />
						<StyledTab label={translate.guarantee} />
					</StyledTabs>
					{!reserved && (
						<Button
							href="https://oplata.info/"
							target="_blank"
							variant="outlined"
							color="primary"
							disableElevation
							style={{ borderRadius: 30, marginRight: 10 }}
						>
							<ShoppingCartOutlinedIcon style={{ marginRight: 6 }} />
							{translate.purchases}
						</Button>
					)}
					<ChangeLanguageButton />
				</div>
			</div>
		</>
	)
}
