import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import styles from './App.module.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NotFound from './components/NotFound/NotFound'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import Main from './components/Main/Main'
import Shop from './components/Shop/Shop'
import Check from './components/Check/Check'
import Contacts from './components/Contacts/Contacts'
import About from './components/About/About'
import BottomMenu from './components/BottomMenu/BottomMenu'
import Policy from './components/Policy/Policy'
import Offer from './components/Offer/Offer'
import Reviews from './components/Reviews/Reviews'
import Guarantee from './components/Guarantee/Guarantee'
import Payment from './components/Payment/Payment'
import Location from './mobx/Location'
import ModalInfo from './components/ModalInfo'
import axios from 'axios'

function CentralContainer() {
	return (
		// <ContentPattern>
		<Switch>
			<Route exact path="/" component={Main} />
			<Route path="/shop" component={Shop} />
			<Route path="/check" component={Check} />
			<Route path="/contacts" component={Contacts} />
			<Route path="/about" component={About} />
			<Route path="/reviews" component={Reviews} />
			<Route path="/guarantee" component={Guarantee} />
			<Route path="/payment" component={Payment} />
			<Route path="/policy" component={Policy} />
			<Route path="/offer" component={Offer} />
			<Route path="/:id" component={BottomMenu} />
			<Route component={NotFound} />
		</Switch>
		// </ContentPattern>
	)
}

export default function App(props: any) {
	// if (window.location.href.includes('uniquecode') && !window.location.href.includes('check')) {
	// 	window.location.href = window.location.origin + '/check' + window.location.search
	// }

	return (
		<Router>
			<ModalInfo />
			<div className={styles.container}>
				<Navbar />
				<CentralContainer />
			</div>
			<Footer />
			<Chat />
		</Router>
	)
}

const Chat = () => {
	const [showChat, setShowChat] = useState(false)

	useEffect(() => {
		axios
			.get('https://api.digiseller.ru/api/shop-settings?seller_id=654077')
			.then(response => {
				const online = response.data?.online_chat
				setShowChat(!!online)
			})
			.catch(console.log)
	}, [])

	const style: CSSProperties = useMemo(
		() => ({
			position: 'fixed',
			bottom: 0,
			right: 50,
			border: 0,
			width: 264,
			height: 42,
			zIndex: 2,
		}),
		[]
	)

	if (!showChat) return null

	return (
		<iframe
			src={`https://chat.digiseller.ru/asp/chatWidget.asp?uid=B51F96D78E21400BA323693DE3B29A46&lang=${Location.getLanguage()}`}
			style={style}
			title={'Chat'}
		/>
	)
}
