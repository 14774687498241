import React from 'react'
import styles from './Main.module.css'
import PaymentVariants from './PaymentVariants'
import starsLogo from '../../images/stars-logo.svg'
import starsLogoMobile from '../../images/stars-logo-mobile.svg'
import starsLogoEn from '../../images/stars-logo-en.svg'
import starsLogoMobileEn from '../../images/stars-logo-mobile-en.svg'
import ContentPattern from '../ContentPattern/ContentPattern'
// import { useTranslate } from '../hooks/useTranslate'
import Location from '../../mobx/Location'

export default function Navbar() {
	// const translate = useTranslate({
	// 	title: { en: 'ONLINE STORE', ru: 'ИНТЕРНЕТ-МАГАЗИН' },
	// 	acceptPayment: { en: 'We accept for payment', ru: 'Принимаем к оплате' },
	// })

	const lang = Location.getLanguage()

	return (
		<>
			<ContentPattern>
				<div className={styles.logoContainer}>
					<img
						className={styles.logoDesctop}
						src={lang === 'ru-RU' ? starsLogo : starsLogoEn}
						alt="logo"
					/>
					<img
						className={styles.logoMobile}
						src={lang === 'ru-RU' ? starsLogoMobile : starsLogoMobileEn}
						alt="logo"
					/>
				</div>

				{/* <div className={styles.container} style={{ marginBottom: 50 }}>
					<div className={styles.textContainer}>
						<img src={logo} className={styles.logo} />
						<span
							className={styles.centralText}
							style={{ color: '#333333', fontWeight: 700, lineHeight: 0.4 }}
						>
							<span className={styles.title}>{translate.title}</span>
							<br />
							<span className={styles.spanTitle}>RICHLEGION</span>
						</span>
					</div>
				</div> */}

				<div className={styles.tickerPayments}>
					<span className={styles.foregroundTickerPayments}></span>
					<PaymentVariants />
				</div>
				{/* <span style={{ fontSize: 14, fontWeight: 600, marginTop: 40, marginBottom: 40 }}>{translate.acceptPayment.toUpperCase()}</span> */}
			</ContentPattern>
		</>
	)
}
