import React from 'react'
import styles from './Shop.module.css'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Location from '../../mobx/Location'
import { observer } from 'mobx-react-lite'
import { useTranslate } from '../hooks/useTranslate'
import { Paper } from '@material-ui/core'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import BackButton from '../BackButton'
import Badge from '@material-ui/core/Badge'
import Sale from './Sale'

interface Params {
	category: string
}

interface Products {
	retval: string
	retdesc: string
	lang: string
	queryId: string
	totalPages: string
	totalItems: string
	breadCrumbs: [
		{
			id: string
			name: string
		}
	]
	categories: []
	product: Product[]
}

interface Product {
	id: string
	name: string
	cntImg: string
	info: string
	price: string
	base_price: string
	base_currency: string
	currency: string
	price_rub: string
	price_usd: string
	price_eur: string
	price_uah: string
	partner_comiss: string
	agency_id: string
	collection: string
	is_available: number
	has_discount: number
	id_present: number
	sale_info: {
		common_base_price: string
		common_price_usd: string
		common_price_rur: string
		common_price_eur: string
		common_price_uah: string
		sale_end: string
		sale_percent: string
	}
	label: 'sale' | 'new' | 'top'
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			maxWidth: 800,
			// backgroundColor: theme.palette.background.paper,
		},
	})
)

const ProductsComponent = observer(({ match }: RouteComponentProps<Params>) => {
	const [products, setProducts] = React.useState<Products>()
	const classes = useStyles()

	const translate = useTranslate({
		loading: { en: 'Loading product list', ru: 'Загрузка списка продуктов' },
		retdesc: { en: 'This category does not exist', ru: 'Такой категории не существует' },
		price: { en: 'Price', ru: 'Цена' },
		notaviable: { en: 'not available', ru: 'нет в наличии' },
		subscribe: { en: 'Notify on availability', ru: 'Уведомить о наличии' },
		subscribeInfo: {
			en: 'Check back later or subscribe to the arrival notification',
			ru: 'Загляните позже или подпишитесь на уведомление о поступлении товара',
		},
	})

	React.useEffect(() => {
		fetch(
			`https://api.digiseller.ru/api/shop/products?lang=${Location.getLanguage()}&seller_id=654077&category_id=${match.params.category}`,
			{
				headers: {
					Accept: 'application/json',
				},
			}
		)
			.then(response => response.json())
			.then(response => {
				setProducts({ ...response })
				console.log(response)
			})
	}, [])

	if (!products) return <>{translate.loading}...</>
	if (products.retdesc) return <>{translate.retdesc}.</>

	console.log(products)

	return (
		<>
			<p className={styles.pathName}>{products.breadCrumbs[0].name}</p>
			<List component="nav" className={classes.root} aria-label="contacts">
				{products.product.map(prod =>
					prod.is_available ? (
						<Paper className={styles.shadow} key={prod.id} style={{ marginBottom: 10 }} elevation={0}>
							<Sale label={prod.label} />
							<ListItem button style={{ backgroundColor: '#ffffff' }} component={Link} to={`/shop/${match.params.category}/${prod.id}`}>
								<ListItemIcon>
									{/* <LocalAtmIcon fontSize="large" style={{ color: '#5bbf97' }} /> */}
									<Avatar
										style={{ width: 60, height: 60, marginRight: 20 }}
										src={
											prod.cntImg !== '0'
												? `https://graph.digiseller.ru/img.ashx?id_d=${prod.id}`
												: 'https://graph.digiseller.ru/img.ashx?id_d=1'
										}
									/>
								</ListItemIcon>

								<ListItemText
									primary={<span className={styles.listPrimary}>{prod.name}</span>}
									secondary={
										<span className={styles.listSecondary}>
											{translate.price}: <span className={styles.colored}>{prod.base_price} ₽</span>
										</span>
									}
								/>
							</ListItem>
						</Paper>
					) : (
						<Paper key={prod.id} style={{ marginBottom: 10 }} elevation={0}>
							<ListItem key={prod.id} style={{ backgroundColor: '#eeeeee' }}>
								<ListItemIcon>
									{/* <LocalAtmIcon fontSize="large" style={{ color: '#5bbf97' }} /> */}
									<Avatar
										style={{ width: 60, height: 60, marginRight: 20, filter: 'grayscale(100%)' }}
										src={`https://graph.digiseller.ru/img.ashx?id_d=${prod.id}&w=60&h=60&crop=true`}
									/>
								</ListItemIcon>

								<ListItemText
									primary={<span className={styles.listPrimary}>{prod.name}</span>}
									secondary={
										<>
											<span className={styles.listSecondary}>
												{translate.price}: <span className={styles.notColored}>{translate.notaviable}</span>
											</span>
											{/* <br />
											<span>{translate.subscribeInfo}.</span> */}
											<br />
											<Button
												onClick={() =>
													window.open(
														`https://www.oplata.info/asp2/pay_wm.asp?id_d=${prod.id}`,
														'',
														'scrollbars=1,height=' +
															Math.min(700, window.innerHeight) +
															',width=' +
															Math.min(500, window.innerWidth) +
															',left=' +
															Math.max(0, (window.innerWidth - 500) / 2) +
															',top=' +
															Math.max(0, (window.innerHeight - 700) / 2)
													)
												}
												size="small"
												variant="outlined"
											>
												<NotificationsNoneIcon style={{ marginRight: 4 }} />
												{translate.subscribe}
											</Button>
										</>
									}
								/>
							</ListItem>
						</Paper>
					)
				)}
			</List>
			<BackButton />
		</>
	)
})

export default ProductsComponent
