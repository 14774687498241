import React from 'react'
import styles from './Check.module.css'
import ContentPattern from '../ContentPattern/ContentPattern'
import shopStyles from '../Shop/Shop.module.css'
import { Paper } from '@material-ui/core'
import { useTranslate } from '../hooks/useTranslate'
import sha256 from '../hooks/sha256'
import Location from '../../mobx/Location'
import Link from '@material-ui/core/Link'
import axios from 'axios'
import logo from '../../images/logo.svg'

interface Receipt {
	retval: number
	retdesc: null | string
	inv: number
	id_goods: number
	amount: number
	profit: number
	type_curr: string
	amount_usd: number
	date_pay: string
	method: string
	email: string
	name_invoice: string
	lang: string
	agent_id: number
	agent_percent: number
	query_string: string
	unit_goods: number
	cnt_goods: number
	unique_code_state: {
		options: number
		date_check: string
	}
	options: Array<{
		id: number
		name: string
		value: string
	}>
}

export default function Check(props: any) {
	const uniquecode = new URLSearchParams(props.location.search).get('uniquecode')
	const [code, setCode] = React.useState<Receipt>()
	const translate = useTranslate({
		title: { en: 'Thank you for your purchase!', ru: 'Спасибо за покупку!' },
		nextTitle: { en: 'Your order has been processed', ru: 'Ваш заказ принят в обработку' },
		desc: {
			en: 'If you notice an error in the specified data, please let us know!',
			ru: 'Если вы заметили ошибку в указанных данных, сообщите нам об этом!',
		},
		productname: { en: 'Product name', ru: 'Название товара' },
		scorenumber: { en: 'Paid invoice number', ru: 'Номер оплаченного счета' },
		uniquecode: { en: 'Unique 16-digit code', ru: 'Уникальный 16-тизначный код' },
		productcount: { en: 'Number of units of paid goods', ru: 'Количество единиц оплачиваемого товара' },
		ty: {
			en: 'We will be grateful for your feedback after receiving the order',
			ru: 'Мы будем благодарны за отзыв после получения заказа',
		},
		datepay: { en: 'Date and time of purchase', ru: 'Дата и время покупки' },
		btn: { en: 'Give feedback', ru: 'Оставить отзыв' },
	})

	React.useEffect(() => {
		;(async function () {
			const time = new Date().getTime()

			axios
				.post('https://api.digiseller.ru/api/apilogin', {
					seller_id: 654077,
					timestamp: time,
					sign: await sha256('B8C5157B1B8340DA9E31BFB3174F7C35' + `${time}`),
				})
				.then(r => {
					fetch(`https://api.digiseller.ru/api/purchases/unique-code/${uniquecode}?token=${r.data.token}`)
						.then(response => response.json())
						.then((response: Receipt) => {
							setCode(response)
							console.log(response)
						})
				})
		})()
	}, [])

	if (!code) return <></>
	// if (typeof code.retdesc === 'string' && code.retdesc.length > 0) return <>{code.retdesc}</>

	return (
		<ContentPattern>
			<>
				{!code && <></>}
				{typeof code.retdesc === 'string' && code.retdesc.length > 0 && <>{code.retdesc}</>}
				{code.retval === 0 && (
					<>
						{/* <div className={styles.textContainer}>
							<img src={logo} className={styles.logo} />
							<span> */}
						<p className={shopStyles.pathName} style={{ fontSize: 20, marginBottom: 0 }}>
							{translate.title}
						</p>
						<p className={shopStyles.pathName} style={{ fontSize: 20, marginTop: 0 }}>
							{translate.nextTitle}
						</p>
						{/* </span>
						</div> */}
						<p className={shopStyles.pathName} style={{ fontSize: 13, fontWeight: 500 }}>
							{translate.desc}
						</p>
						<section>
							<div className={styles['tbl-content']}>
								<table className={styles['table-content']}>
									<tbody>
										<tr>
											<td className={styles['td']}>{translate.productname}</td>
											<td className={styles['td']}>{code.name_invoice}</td>
										</tr>
										<tr>
											<td className={styles['td']}>{translate.datepay}</td>
											<td className={styles['td']}>{code.date_pay}</td>
										</tr>
										<tr>
											<td className={styles['td']}>{translate.scorenumber}</td>
											<td className={styles['td']}>{code.inv}</td>
										</tr>
										<tr>
											<td className={styles['td']}>{translate.uniquecode}</td>
											<td className={styles['td']}>{uniquecode}</td>
										</tr>
										{code?.options.length &&
											code.options.map(item => (
												<tr key={item.id}>
													<td className={styles['td']}>{item.name}</td>
													<td className={styles['td']}>{item.value}</td>
												</tr>
											))}
										<tr>
											<td className={styles['td']}>{translate.productcount}</td>
											<td className={styles['td']}>{code.cnt_goods * code.unit_goods}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</section>
						<p>
							{translate.ty}{' '}
							<Link
								style={{ background: '#4caf50', padding: '4px 14px 2px', borderRadius: 8, color: '#ffffff' }}
								href="https://oplata.info"
								target="_blank"
							>
								{translate.btn}
							</Link>
						</p>
					</>
				)}
			</>
		</ContentPattern>
	)
}
