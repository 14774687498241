import React from 'react'
import styles from './Main.module.css'

import t2 from '../../images/payments/t2.png'
import umoney from '../../images/payments/umoney.png'
import sberbank from '../../images/payments/sberbank.png'
import mir from '../../images/payments/svg/mir.svg'
import mastercard from '../../images/payments/svg/mastercard.svg'
import visa from '../../images/payments/svg/visa.svg'
import ALP from '../../images/payments/svg/ALP.svg'
import BTC from '../../images/payments/svg/BTC.svg'
import IK1 from '../../images/payments/svg/IK1.svg'
import IK2 from '../../images/payments/svg/IK2.svg'
import IK3 from '../../images/payments/svg/IK3.svg'
import MMR from '../../images/payments/svg/MMR.svg'
import PLR from '../../images/payments/svg/PLR.svg'
import QSP from '../../images/payments/svg/QSP.svg'
import SFT from '../../images/payments/svg/SFT.svg'
import UNP from '../../images/payments/svg/UNP.svg'
import WMR from '../../images/payments/svg/WMR.svg'
import alfabank from '../../images/payments/svg/nalfabank.svg'
import PSB from '../../images/payments/svg/nPSB.svg'
import vtb from '../../images/payments/svg/nvtb.svg'
import nrussianStandart from '../../images/payments/svg/nrussianStandart.svg'

interface ShowPayments {
	webmoney: boolean
	qiwi: boolean
	umoney: boolean
	visa: boolean
	mastercard: boolean
	mir: boolean
	mts: boolean
	beeline: boolean
	megaphone: boolean
	tele2: boolean
	steam: boolean
	sberbank: boolean
	alfabank: boolean
	psb: boolean
	vtb: boolean
	russianstandart: boolean
	paypal: boolean
	bitcoin: boolean
	alipay: boolean
	turkeybankcard: boolean
	unionpay: boolean
	speed: number
}

export default function Navbar() {
	const [methods, setMethods] = React.useState<Array<{ id: number; show: boolean; props: { src: string; style?: any } }>>([])
	const [speed, setSpeed] = React.useState<number>()

	React.useEffect(() => {
		fetch(`/payments.json?${new Date().getTime()}`)
			.then(response => response.json())
			.then((response: ShowPayments) => {
				setSpeed(response.speed)
				setMethods([
					{ id: 1, show: response.webmoney, props: { src: WMR } },
					{ id: 2, show: response.qiwi, props: { src: QSP } },
					{ id: 3, show: response.umoney, props: { src: umoney } },
					{
						id: 4,
						show: response.visa,
						props: {
							src: visa,
							style: {
								height: 30,
							},
						},
					},
					{ id: 5, show: response.mastercard, props: { src: mastercard } },
					{
						id: 6,
						show: response.mir,
						props: {
							src: mir,
							style: {
								height: 36,
							},
						},
					},
					{ id: 7, show: response.mts, props: { src: IK1 } },
					{ id: 8, show: response.beeline, props: { src: IK2 } },
					{ id: 9, show: response.megaphone, props: { src: IK3 } },
					{ id: 10, show: response.tele2, props: { src: t2 } },
					{ id: 11, show: response.steam, props: { src: MMR } },
					{ id: 12, show: response.sberbank, props: { src: sberbank } },
					{ id: 18, show: response.alfabank, props: { src: alfabank } },
					{ id: 19, show: response.psb, props: { src: PSB } },
					{ id: 20, show: response.vtb, props: { src: vtb } },
					{ id: 21, show: response.russianstandart, props: { src: nrussianStandart } },
					{ id: 13, show: response.paypal, props: { src: PLR } },
					{ id: 14, show: response.bitcoin, props: { src: BTC } },
					{ id: 15, show: response.alipay, props: { src: ALP } },
					{ id: 16, show: response.turkeybankcard, props: { src: SFT } },
					{ id: 17, show: response.unionpay, props: { src: UNP } },
				])
			})
	}, [])

	if (!methods.length) return <></>

	return (
		<div className={styles.paymentContainer}>
			{/* <p>Принимаем к оплате:</p> */}
			<div className={styles.paymentContainerImages} style={{ animationDuration: `${speed}s` }}>
				{methods.map(image => {
					if (image.show) return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
				{methods.map(image => {
					if (image.show) return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
				{methods.map(image => {
					if (image.show) return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
				{methods.map(image => {
					if (image.show) return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
			</div>
			{/* <div className={styles.paymentContainerImagesReverse}>
				{images.map(image => {
					return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
				{images.map(image => {
					return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
				{images.map(image => {
					return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
				{images.map(image => {
					return <img key={image.id} {...image.props} className={styles.paymentImage} />
				})}
			</div> */}
		</div>
	)
}
