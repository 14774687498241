import Location from '../../mobx/Location'

interface Translate {
	[keys: string]: {
		ru: string
		en: string
	}
}

export function useTranslate<T extends Translate>(translate: T): Record<keyof T, string> {
	const lang = Location.getLanguage()
	const response: any = {}

	Object.keys(translate).forEach(name => {
		if (lang === 'ru-RU') {
			response[name] = translate[name].ru
		} else {
			response[name] = translate[name].en
		}
	})

	return response
}
