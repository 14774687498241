/**
 * Шифрование текста в sha256
 * @param message Текст для шифрования
 * @returns Hash зашифрованного текста
 */
export default async function sha256(message: string): Promise<string> {
	const msgBuffer = new TextEncoder().encode(message);
	const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');

	return hashHex
}
