import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Location from '../mobx/Location'
import { useTranslate } from './hooks/useTranslate'

interface Info {
	queryId: string
	header: string
	info: string
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	})

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string
	children: React.ReactNode
	onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions)

export default function CustomizedDialogs() {
	const [open, setOpen] = React.useState(true)
	const [response, setResponse] = React.useState<Info>()

	const translate = useTranslate({
		notification: { en: 'Notification', ru: 'Уведомление' },
	})

	React.useEffect(() => {
		fetch(
			`https://shop.digiseller.ru/xml/shop_menu.asp?transp=cors&format=json&lang=${Location.getLanguage()}&seller_id=654077&menu=refund`
		)
			.then(response => response.json())
			.then(response => {
				setResponse(response)
			})
	}, [])

	if (!response?.info) return <></>
	// if (localStorage.getItem('info' + Location.getLanguage()) === response.info) return <></>

	const handleClose = () => {
		setOpen(false)
		// localStorage.setItem('info' + Location.getLanguage(), response.info)
	}

	return (
		<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle id="customized-dialog-title" onClose={handleClose}>
				{translate.notification}
			</DialogTitle>
			<DialogContent dividers>
				<Typography style={{ wordBreak: 'break-word' }} gutterBottom dangerouslySetInnerHTML={{ __html: response.info }} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}
