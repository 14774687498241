import React from 'react'
import styles from './Calculator.module.css'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import { useTranslate } from '../../hooks/useTranslate'
import { Product } from '../ProductComponent'

import queryString from 'query-string'
import Location from '../../../mobx/Location'
import getPrice from './getPrice'

// import payment_methods from './paymentMethods'

interface Calc {
	retval: number
	retdesc: string
	data: {
		price: number
		count: number
		amount: number
		currency: string
		commission: number
		free_pay: unknown
		sale_info: {
			common_base_price: number
			sale_percent: number
		}
	}
}

export default function Unit({ product }: Product) {
	const translate = useTranslate({
		buy: { en: 'Buy', ru: 'Купить' },
		way: { en: 'Payment method', ru: 'Способ оплаты' },
		minPrice: { en: 'Minimum you can buy', ru: 'Минимум вы можете купить' },
		maxPrice: { en: 'Maximum you can buy', ru: 'Максимум вы можете купить' },
	})

	const [paymentMethods, setPaymentMethods] = React.useState(0)
	const [currency, setCurrency] = React.useState(0)
	const [money, setMoney] = React.useState(0)
	const [gold, setGold] = React.useState(0)
	const [options, setOptions] = React.useState({})
	const [buy, setBuy] = React.useState(true)

	const [payment_methods, setMethods] = React.useState<CalcMethods[]>()
	const price = payment_methods && getPrice(money, payment_methods[paymentMethods].percent)

	React.useEffect(() => {
		fetch(`/calculator_methods.json?${new Date().getTime()}`, { cache: 'no-cache' })
			.then(response => response.json())
			.then((response: { methods: CalcMethods[] }) => {
				setMethods(response.methods)

				const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
					response.methods[paymentMethods].currency
				}&unit_cnt=${1}`

				fetch(uri)
					.then(response => response.json())
					.then((response: Calc) => {
						setGold(response.data.count)
						setMoney(response.data.amount)
					})

				product.options.forEach(option => {
					setOptions(prev => {
						return { ...prev, [option.name]: '' }
					})
				})
			})
	}, [])

	const handleChangeOptions = (event: React.ChangeEvent<{ value: string; name: string }>) => {
		// setOptions(event.target.value as number)
		setOptions(prev => {
			return { ...prev, [event.target.name]: event.target.value }
		})
	}

	const handleChangePaymentMethods = (event: React.ChangeEvent<{ value: unknown }>) => {
		const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
			payment_methods && payment_methods[event.target.value as number].currency
		}&unit_cnt=${gold}`

		setBuy(false)
		setCurrency(0)
		fetch(uri)
			.then(response => response.json())
			.then((response: Calc) => {
				setMoney(response.data.amount)
				setGold(response.data.count)
				setBuy(true)
			})
		setPaymentMethods(event.target.value as number)
	}

	const handleChangeCurrency = (event: React.ChangeEvent<{ value: unknown }>) => {
		setBuy(false)
		const uri = `https://api.digiseller.ru/api/products/price/calc?product_id=${product.id}&currency=${
			payment_methods && payment_methods[paymentMethods].currency
		}&unit_cnt=${gold}`

		fetch(uri)
			.then(response => response.json())
			.then((response: Calc) => {
				setMoney(response.data.amount)
				setGold(response.data.count)
				setBuy(true)
			})
		setCurrency(event.target.value as number)
	}

	const [select, setSelect] = React.useState('')
	const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelect(event.target.value as string)
	}

	const [selectDesc, setSelectDesc] = React.useState('')

	if (!payment_methods) return <></>
	if (!price) return <></>

	return (
		<div>
			<h3 style={{ marginTop: 10, marginBottom: 10 }}>
				{money}
				{price.percentFull > 0 && ` (+${price.percentFull})`} {payment_methods[paymentMethods].currency === 'WMR' && '₽'}
				{payment_methods[paymentMethods].currency === 'WMZ' && '$'}
				{payment_methods[paymentMethods].currency === 'WME' && '€'}
			</h3>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<FormControl
					style={{
						marginRight: product.payment_methods[paymentMethods].currencies.length > 1 ? '2%' : 0,
						width: '100%',
					}}
				>
					<InputLabel htmlFor="payment_methods">{translate.way}</InputLabel>
					<Select
						native
						value={paymentMethods}
						onChange={handleChangePaymentMethods}
						inputProps={{
							id: 'payment_methods',
						}}
					>
						{payment_methods.map((method, index) => {
							if (method.active)
								return (
									<option key={method.name[Location.getLanguage()]} value={index}>
										{method.name[Location.getLanguage()]}
									</option>
								)
						})}
					</Select>
				</FormControl>
				{/* {product.payment_methods[paymentMethods].currencies.length > 1 ? (
					<FormControl style={{ width: 80 }}>
						<InputLabel htmlFor="currency"></InputLabel>
						<Select
							native
							value={currency}
							onChange={handleChangeCurrency}
							inputProps={{
								id: 'currency',
							}}
						>
							{product.payment_methods[paymentMethods].currencies.map((currency, index) => {
								return (
									<option key={currency.code} value={index}>
										{currency.currency}
									</option>
								)
							})}
						</Select>
					</FormControl>
				) : null} */}
			</div>
			<br />
			<Divider />
			<br />
			{product.options.length ? (
				<>
					{product.options.map((option, index) => {
						if (option.type === 'text') {
							return (
								<>
									<TextField
										key={option.name}
										name={option.name}
										label={
											<span style={{ letterSpacing: 0 }}>
												<Tooltip disableFocusListener disableTouchListener title={<span style={{ fontSize: 11 }}>{option.comment}</span>}>
													<span style={{ padding: '0 4px', borderRadius: '50%', color: '#ffffff', background: '#3f51b5' }}>?</span>
												</Tooltip>{' '}
												{option.label}
											</span>
										}
										onChange={handleChangeOptions}
										type="text"
										InputLabelProps={{
											shrink: true,
										}}
										// value={money}
										// onChange={handleChangeMoney}
										style={{ width: '100%' }}
									/>
									{index !== product.options.length - 1 ? (
										<>
											<br />
											<br />
										</>
									) : null}
								</>
							)
						}

						if (option.type === 'select') {
							return (
								<>
									<FormControl key={option.name} style={{ width: '100%' }}>
										<InputLabel>{option.label}</InputLabel>
										<Select value={select} onChange={handleChangeSelect}>
											{option.variants &&
												option.variants.map(variant => {
													return (
														<MenuItem
															key={variant.value}
															value={variant.value}
															onClick={() => {
																setSelectDesc(`${variant.text}`)
																setOptions(prev => {
																	return { ...prev, [option.name]: variant.text }
																})
															}}
														>
															{variant.text}
														</MenuItem>
													)
												})}
										</Select>
									</FormControl>
									{index !== product.options.length - 1 ? (
										<>
											<br />
											<br />
										</>
									) : null}
								</>
							)
						}
					})}
				</>
			) : null}
			<br />
			<br />
			{/* <Button
				onClick={() => {
					console.log(options)

					fetch('https://api.digiseller.ru/api/purchases/options', {
						method: 'POST',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							product_id: product.id,
							options: Object.entries(options).map(([key, value]) => {
								return { id: key, value: { text: value } }
							}),
							unit_cnt: gold,
							lang: Location.getLanguage(),
							// ip: Location.getIP(),
						}),
					})
						.then(response => response.json())
						.then(response => {
							window.open(
								`https://www.digiseller.market/asp2/pay_wm.asp?id_d=${product.id}&id_po=${response.id_po}&failpage=${
									window.location
								}&lang=${Location.getLanguage()}&curr=${product.payment_methods[paymentMethods].currencies[currency].code}`,
								'_self'
							)
						})
				}}
				disableElevation
				variant="contained"
				color="primary"
				className={styles.buyBtn}
				disabled={Object.values(options).some(e => !(e as string).trim())}
			>
				{translate.buy}
			</Button> */}
			{(Object.values(options).some(e => !(e as string).trim()) && !selectDesc.trim()) || !buy ? (
				<>
					<Button disabled disableElevation variant="contained" color="primary" className={styles.buyBtn}>
						{translate.buy}
					</Button>
				</>
			) : (
				<>
					{/* WEBMONEY */}
					{(payment_methods[paymentMethods].code === 'wmr' ||
						payment_methods[paymentMethods].code === 'wmp' ||
						payment_methods[paymentMethods].code === 'wmz' ||
						payment_methods[paymentMethods].code === 'wme') && (
						<form method="POST" target="_blank" acceptCharset="windows-1251" action="https://merchant.webmoney.ru/lmi/payment_utf.asp">
							<input type="hidden" name="LMI_PAYMENT_AMOUNT" value={price.priceFull} />
							<input type="hidden" name="LMI_PAYEE_PURSE" value={payment_methods[paymentMethods].wallet} />
							<input
								type="hidden"
								name="LMI_PAYMENT_DESC"
								value={`${product.name}; ${
									Object.entries(options).length &&
									Object.entries(options)
										.map(([key, value], index) => {
											return `${value};`
										})
										.join(' ')
								}`}
							/>
							<input type="hidden" name="DOMAIN_MODE" value="2" />
							<input type="hidden" name="LMI_PAYMENT_NO" value={product.id} />
							<Button type="submit" disableElevation variant="contained" color="primary" className={styles.buyBtn}>
								{translate.buy}
							</Button>
						</form>
					)}

					{/* YOOMONEY */}
					{(payment_methods[paymentMethods].code === 'yoo' ||
						payment_methods[paymentMethods].code === 'yoo_card' ||
						payment_methods[paymentMethods].code === 'yoo_phones') && (
						<form method="POST" target="_blank" action="https://yoomoney.ru/quickpay/confirm.xml">
							<input type="hidden" name="receiver" value={payment_methods[paymentMethods].wallet} />
							<input type="hidden" name="formcomment" value={product.name} />
							<input type="hidden" name="short-dest" value={product.name} />
							<input type="hidden" name="label" value={product.name} /> <input type="hidden" name="quickpay-form" value="donate" />
							<input type="hidden" name="targets" value={product.name} />
							<input type="hidden" name="sum" value={price.priceFull} data-type="number" />
							<input
								type="hidden"
								name="comment"
								value={`${product.name}; ${
									Object.entries(options).length &&
									Object.entries(options)
										.map(([key, value], index) => {
											return `${value};`
										})
										.join(' ')
								}`}
							/>
							<input type="hidden" name="need-fio" value="false" /> <input type="hidden" name="need-email" value="false" />
							<input type="hidden" name="need-phone" value="false" /> <input type="hidden" name="need-address" value="false" />
							<input
								type="hidden"
								name="paymentType"
								value={
									payment_methods[paymentMethods].code === 'yoo'
										? 'PC'
										: payment_methods[paymentMethods].code === 'yoo_card'
										? 'AC'
										: payment_methods[paymentMethods].code === 'yoo_phones'
										? 'MC'
										: ''
								}
							/>
							<Button type="submit" disableElevation variant="contained" color="primary" className={styles.buyBtn}>
								{translate.buy}
							</Button>
						</form>
					)}

					{(payment_methods[paymentMethods].code === 'qiwi' || payment_methods[paymentMethods].code === 'qiwi_card') && (
						<Button
							href={`https://qiwi.com/payment/form/99
							?extra%5B%27account%27%5D=${payment_methods[paymentMethods].wallet}
							&amountInteger=${price.priceLeft}
							&amountFraction=${price.priceRight}
							&extra%5B%27comment%27%5D=${`${product.name}; ${
								Object.entries(options).length &&
								Object.entries(options)
									.map(([key, value], index) => {
										return `${value};`
									})
									.join(' ')
							}`}
							&currency=643
							&blocked[0]=account
							`}
							target="_blank"
							disableElevation
							variant="contained"
							color="primary"
							className={styles.buyBtn}
						>
							{translate.buy}
						</Button>
					)}
				</>
			)}
		</div>
	)
}
