import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import { useHistory } from 'react-router-dom'
import { useTranslate } from '../../hooks/useTranslate'
import Location from '../../../mobx/Location'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: 'relative',
			background: '#000000',
			backgroundColor: '#000000',
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
	})
)

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

type FSMenu = {
	openMinNav: boolean
	setOpenMinNav: any
	paths: Array<string>
}

export default function FullScreenDialog({ openMinNav, setOpenMinNav, paths }: FSMenu) {
	const classes = useStyles()
	const history = useHistory()
	const translate = useTranslate({
		faq: { en: 'FAQ', ru: 'FAQ' },
		home: { en: 'Home', ru: 'Главная' },
		shop: { en: 'Store', ru: 'Магазин' },
		reviews: { en: 'Reviews', ru: 'Отзывы' },
		guarantee: { en: 'Guarantee', ru: 'Гарантия' },
		suppliers: { en: 'Suppliers', ru: 'Поставщикам' },
		lang: { en: 'Change language', ru: 'Сменить язык' },
		purchases: { en: 'My purchases', ru: 'Мои покупки' },
	})

	const handleClose = (pushInbex: number) => {
		setOpenMinNav(false)
		// if (paths[pushInbex] === '/reviews') {
		// 	history.push('/reviews/1')
		// 	return
		// }
		history.push(paths[pushInbex])
	}

	const [reserved, setReserved] = React.useState(false)

	React.useEffect(() => {
		fetch('/payments.json')
			.then(response => response.json())
			.then((response: any) => {
				if (response['zapasnaya_oplata']) setReserved(true)
			})
	}, [])

	return (
		<div>
			<Dialog fullScreen open={openMinNav} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar style={{ background: '#000000' }}>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => setOpenMinNav(false)}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<List>
					<ListItem button>
						<ListItemText primary={translate.home} onClick={() => handleClose(0)} />
					</ListItem>
					<Divider />
					<ListItem button>
						<ListItemText primary={translate.shop} onClick={() => handleClose(1)} />
					</ListItem>
					<Divider />
					<ListItem button>
						<ListItemText primary={translate.reviews} onClick={() => handleClose(2)} />
					</ListItem>
					<Divider />
					<ListItem button>
						<ListItemText primary={translate.guarantee} onClick={() => handleClose(3)} />
					</ListItem>
					<Divider />
					{!reserved && (
						<ListItem button component="a" target="_blank" href="https://oplata.info/">
							<ListItemText primary={translate.purchases} />
						</ListItem>
					)}
					<Divider />
					<ListItem button>
						<ListItemText
							primary={translate.lang}
							secondary={
								<>
									<span>
										<span style={{ color: Location.getLanguage() === 'en-US' ? '#3c4858' : '' }}>
											EN
										</span>{' '}
										/{' '}
										<span style={{ color: Location.getLanguage() === 'ru-RU' ? '#3c4858' : '' }}>
											RU
										</span>
									</span>
								</>
							}
							onClick={() => {
								if (Location.getLanguage() === 'en-US') {
									Location.changeLanguage('ru-RU')
								} else {
									Location.changeLanguage('en-US')
								}
								window.location.reload()
							}}
						/>
					</ListItem>
				</List>
			</Dialog>
		</div>
	)
}
