import React from 'react'
import styles from './Calculator.module.css'

import Unit from './Unit'
import Pins from './Pins'

import { Paper } from '@material-ui/core'

import { Product } from '../ProductComponent'

export default function Calculator(product: Product) {
	return (
		<Paper className={styles.container} elevation={3}>
			{product.product.collection === 'unit' && <Unit {...product} />}
			{product.product.collection === 'pins' && <Pins {...product} />}
		</Paper>
	)
}
