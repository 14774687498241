import React from 'react'
import styles from './Guarantee.module.css'
import { Paper } from '@material-ui/core'
import { useTranslate } from '../hooks/useTranslate'
import Location from '../../mobx/Location'
import shopStyles from '../Shop/Shop.module.css'
import ContentPattern from '../ContentPattern/ContentPattern'

interface Info {
	header: string
	info: string
	queryId: string
}

export default function Guarantee() {
	const [guaranteeInfo, setGuaranteeInfo] = React.useState<Info>()
	const translate = useTranslate({
		loading: { en: 'Loading info', ru: 'Загрузка информации' },
	})

	React.useEffect(() => {
		fetch(
			`https://shop.digiseller.ru/xml/shop_menu.asp?transp=cors&format=json&lang=${Location.getLanguage()}&seller_id=654077&menu=guarantee`
		)
			.then(response => response.json())
			.then(response => {
				setGuaranteeInfo(response)
			})
	}, [])

	if (!guaranteeInfo) return <ContentPattern>{translate.loading}...</ContentPattern>

	return (
		<ContentPattern>
			<p className={shopStyles.pathName}>{guaranteeInfo.header}</p>
			<Paper className={styles.container} elevation={0}>
				<div dangerouslySetInnerHTML={{ __html: guaranteeInfo.info }} />
			</Paper>
		</ContentPattern>
	)
}
