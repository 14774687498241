import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ProductsComponent from './ProductsComponent'
import ProductComponent from './ProductComponent'

export default function ProductsRoute() {
	return (
		<Switch>
			<Route exact path="/shop/:category" component={ProductsComponent} />
			<Route path="/shop/:category/:product" component={ProductComponent} />
		</Switch>
	)
}
