import React from 'react'
import styles from './Footer.module.css'
import MaterialLink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import wmImage from '../../images/wm_persona.png'
import { useTranslate } from '../hooks/useTranslate'

import ListItemText from '@material-ui/core/ListItemText'
import Location from '../../mobx/Location'

interface Response {
	currency: string
	view: string
	rows: number
	logo_img: string
	main_view: string
	menu_purchases: boolean
	menu_reviews: boolean
	menu_contacts: boolean
	menu: string[]
	bottom_menu: {
		id: number
		name_ru: string
		name_en: string
	}[]
	online_chat: number
	imgsize_firstpage: number
	imgsize_listpage: number
	imgsize_infopage: number
	imgsize_category: number
	show_feedbacks: number
	pay_target_self: number
	lang: string
	buy_url: string
}

export default function Footer() {
	const translate = useTranslate({
		about: { en: 'About Store', ru: 'О магазине' },
		contacts: { en: 'Contacts', ru: 'Контакты' },
		payment: { en: 'Shipping and payment', ru: 'Доставка и оплата' },
		suppliers: { en: 'Suppliers', ru: 'Поставщикам' },
		policy: { en: 'Privacy policy', ru: 'Политика конфиденциальности' },
		offer: { en: 'Offer agreement', ru: 'Договор-оферта' },
	})

	const [bottomMenu, setBottomMenu] = React.useState<Response>()

	React.useEffect(() => {
		fetch(`https://api.digiseller.ru/api/shop-settings?seller_id=654077`)
			.then(response => response.json())
			.then(response => {
				setBottomMenu(response)
			})
	}, [])

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				{/* <div className={styles.link} style={{ color: '#3c4858', fontWeight: 500 }}>
					{translate.contacts}:
					<Contacts />
				</div>
				<div className={styles.link} style={{ color: '#3c4858' }}>
					<MaterialLink className={styles.link} component={Link} to="/">
						{translate.about}
					</MaterialLink>
					<br />
					<MaterialLink className={styles.link} component={Link} to="/">
						{translate.guarantee}
					</MaterialLink>
					<br />
					<MaterialLink className={styles.link} component={Link} to="/">
						{translate.payment}
					</MaterialLink>
					<br />
					<MaterialLink className={styles.wmImage} href="https://passport.webmoney.ru/asp/certview.asp?wmid=959237439452" target="_blank">
						<img src={wmImage} />
					</MaterialLink>
				</div> */}
				{/* <MaterialLink className={styles.wmImage} href="https://passport.webmoney.ru/asp/certview.asp?wmid=959237439452" target="_blank">
					<img src={wmImage} />
				</MaterialLink> */}
				<MaterialLink
					className={styles.link}
					component={Link}
					to="/contacts"
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' })
					}}
				>
					{translate.contacts}
				</MaterialLink>
				<MaterialLink
					className={styles.link}
					target="_blank"
					href="https://docs.google.com/forms/d/e/1FAIpQLSeUOcLClQrMCsnmWeR5DaA9_6IMVZg_klO_QwvfIMoHd5fEog/viewform"
				>
					{translate.suppliers}
				</MaterialLink>
				<MaterialLink
					className={styles.link}
					component={Link}
					to="/about"
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' })
					}}
				>
					{translate.about}
				</MaterialLink>
				<MaterialLink
					className={styles.link}
					component={Link}
					to="/payment"
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' })
					}}
				>
					{translate.payment}
				</MaterialLink>
			</div>
			<div className={styles.contentBottom}>
				{bottomMenu?.bottom_menu.map(item => {
					return (
						<MaterialLink
							key={item.id}
							className={styles.link}
							component={Link}
							to={`/${item.name_en.replace(/ /g, '-').toLowerCase()}`}
							onClick={() => {
								window.scrollTo({ top: 0, behavior: 'smooth' })
							}}
						>
							{Location.getLanguage() === 'ru-RU' ? item.name_ru : item.name_en}
						</MaterialLink>
					)
				})}
			</div>
		</div>
	)
}
