import React from 'react'
import styles from './Shop.module.css'
import { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Location from '../../mobx/Location'
import { useTranslate } from '../hooks/useTranslate'
import { Paper } from '@material-ui/core'
import Image from 'material-ui-image'
import Calculator from './Calculator/Calculator'
// import Button from '@material-ui/core/Button'
import Button from '../Button'
import Sales from './Sales/Sales'
import BackButton from '../BackButton'
import Guarantee from './Guarantee/Guarantee'
import Sale from './Sale'
import queryString from 'query-string'

interface Params {
	product: string
}

export interface Product {
	retval: number
	retdesc: ''
	product: {
		id: number
		id_prev: number
		id_next: number
		name: string
		price: number
		currency: string
		url: string
		info: string
		add_info: string
		release_date: string
		agency_fee: number
		agency_sum: number
		agency_id: number
		collection: 'book' | 'digi' | 'pins' | 'soft' | 'unit'
		propertygood: 1 | 2
		is_available: 0 | 1
		pwyw: number
		label: 'sale' | 'new' | 'top'
		no_cart: number
		preview_imgs: [{ id: number; url: string; width: number; height: number }]
		prices: [
			{
				code: string
				price: number
				currency: string
			}
		]
		payment_methods: [
			{
				name: string
				code: string
				hide: number
				currencies: [
					{
						currency: string
						code: string
					}
				]
			}
		]
		prices_unit: {
			unit_name: string
			unit_amount: number
			unit_amount_desc: string
			unit_currency: string
			unit_cnt: number
			unit_cnt_min: number
			unit_cnt_desc: string
			unit_only_int?: number
		}
		type: 'text' | 'file'
		text: {
			date: string
			size: number
		}
		category_id: number
		breadcrumbs: [
			{
				id: number
				name: string
				products_cnt: number
			}
		]
		units: {
			desc: string
			price: number
			discounts: [
				{
					desc: string
					percent: number
					price: number
				}
			]
		}
		options: [
			{
				name: string
				label: string
				comment: string
				type: 'textarea' | 'checkbox' | 'text' | 'radio' | 'select'
				required: number
				variants?: {
					default: number
					modify: string
					modify_type: string
					modify_value: number
					text: string
					value: number
				}[]
			}
		]
		options_check: number
		statistics: {
			sales: number
			sales_hidden: number
			refunds: number
			refunds_hidden: number
			good_reviews: number
			good_reviews_hidden: number
			bad_reviews: number
			bad_reviews_hidden: number
		}
		seller: {
			id: number
			name: string
		}
		discounts: [
			{
				summa: number
				percent: number
			}
		]
	}
}

const ProductComponent = observer(({ match }: RouteComponentProps<Params>) => {
	const [product, setProduct] = React.useState<Product>()
	const [price, setPrice] = React.useState<number>()
	const [reserved, setReserved] = React.useState(false)
	const translate = useTranslate({
		loading: { en: 'Loading description', ru: 'Загрузка описания' },
		retdesc: { en: 'This product does not exist', ru: 'Такого товара не существует' },
		buy: { en: 'Buy', ru: 'Купить' },
		discounts: { en: 'Discounts', ru: 'Cкидки' },
		addInfo: { en: 'Additional Information', ru: 'Дополнительная информация' },
		description: { en: 'Description', ru: 'Описание' },
	})

	React.useEffect(() => {
		fetch(`/payments.json${new Date().getTime()}`)
			.then(response => response.json())
			.then((response: any) => {
				if (response['zapasnaya_oplata']) setReserved(true)
			})

		fetch(
			`https://api.digiseller.ru/api/products/${match.params.product}/info?lang=${Location.getLanguage()}&currency=RUB&seller_id=654077`,
			{
				headers: {
					Accept: 'application/json',
				},
			}
		)
			.then(response => response.json())
			.then(prod => {
				console.log(prod)
				setProduct(prod)

				if (prod.retdesc) return

				fetch(
					`https://api.digiseller.ru/api/products/price/calc?product_id=${prod.product.id}&currency=WMR${
						prod.product.collection === 'unit' ? `&unit_cnt=${prod.product.prices_unit.unit_cnt}` : ''
					}`,
					{
						headers: {
							Accept: 'application/json',
						},
					}
				)
					.then(response => response.json())
					.then(response => {
						if (prod.product.collection === 'unit') {
							setPrice(response.data.amount)
						} else {
							setPrice(response.data.price)
						}

						console.log(response)
					})
			})
	}, [])

	if (!product) return <>{translate.loading}...</>
	if (product.retdesc) return <>{translate.retdesc}.</>

	return (
		<React.Suspense fallback={<></>}>
			<Paper className={styles.productPaper} elevation={0}>
				<p className={styles.productName}>{product.product.name}</p>

				<div className={styles.productHeader}>
					<div className={styles.productCard}>
						<Sale label={product.product.label} big />
						<div
							className={styles.productImg}
							style={{
								minWidth: 180,
								minHeight: 180,
								borderRadius: '8px',
								overflow: 'hidden',
							}}
						>
							{/* <Image src={`http://graph.digiseller.ru/img.ashx?id_d=${product.product.id}&w=400&h=400&crop=true`} /> */}
							<Image
								src={product.product?.preview_imgs ? product.product.preview_imgs[0].url : 'https://graph.digiseller.ru/img.ashx?id_d=1'}
							/>
						</div>
						{/* <span className={styles.productPrice}>{price} ₽</span> */}
						<Guarantee />
						{reserved === false && (
							<form id="digiselller_form" action="https://oplata.info/asp2/pay.asp" method="post">
								<input type="hidden" name="product_id" value={product.product.id} />
								<input type="hidden" name="id_d" value={product.product.id} />
								<input type="hidden" name="lang" value={Location.getLanguage()} />
								<input type="hidden" name="failpage" value={window.location.toString()} />
								<input type="hidden" name="seller_id" value="654077" />
								<input type="hidden" name="_ids_shop" value="654077" />
								<input type="hidden" name="_ow" value="0" />
								<Button type="submit" style={{ width: '100%' }}>
									{translate.buy}
								</Button>
							</form>
						)}

						{reserved === true && (
							<>
								<br />
								<Calculator {...product} />
							</>
						)}

						{/* ({price} ₽)*/}
					</div>
					<div className={styles.productMainInfo}>
						<span className={styles.productMainInfoName}>{translate.description}</span>
						<div className={styles.productMainInfoDesc} dangerouslySetInnerHTML={{ __html: product.product.info }}></div>
						{product.product?.add_info && (
							<>
								<span className={styles.productMainInfoName}>{translate.addInfo}</span>
								<div className={styles.productMainInfoDesc} dangerouslySetInnerHTML={{ __html: product.product.add_info }}></div>
							</>
						)}

						{product?.product?.discounts || product?.product?.units?.discounts ? (
							<>
								<span className={styles.productMainInfoName}>{translate.discounts}</span>
								<Sales {...product} />
							</>
						) : null}
					</div>
				</div>
			</Paper>

			<BackButton />
		</React.Suspense>
	)
})

export default ProductComponent
