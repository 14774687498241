import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CategoriesComponent from './CategoriesComponent'
import ProductsRoute from './ProductsRoute'

export default function CategoriesRoute() {
	return (
		<Switch>
			<Route exact path="/shop" component={CategoriesComponent} />
			<Route path="/shop/:category" component={ProductsRoute} />
		</Switch>
	)
}
