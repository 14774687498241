import React from 'react'
import styles from './NotFound.module.css'
import { Grid } from '@material-ui/core'
import { useTranslate } from '../hooks/useTranslate'
import ContentPattern from '../ContentPattern/ContentPattern'

export default function Navbar() {
	const translate = useTranslate({
		notFound: { en: 'Page not found', ru: 'Страница не найдена' },
	})
	return (
		<ContentPattern>
			<div className={styles.container}>
				<Grid container direction="row" justify="center" alignItems="center" className={styles.container}>
					<p className={styles.head}>
						<b>Error 404</b>
						<br />
						{translate.notFound}.
					</p>
				</Grid>
			</div>
		</ContentPattern>
	)
}
