import React from 'react'
import styles from './Shop.module.css'
import { useTranslate } from '../hooks/useTranslate'

export default function Sale({ label, big }: { label: 'sale' | 'new' | 'top'; big?: boolean }) {
	const translate = useTranslate({
		sale: { en: 'Discount', ru: 'Акция' },
		new: { en: 'New', ru: 'Новинка' },
		top: { en: 'Top seller', ru: 'Лидер продаж' },
	})

	if (label === 'sale') return <span className={big ? styles.saleBig : styles.sale}>{translate.sale}!</span>
	if (label === 'new') return <span className={big ? styles.newBig : styles.new}>{translate.new}!</span>
	if (label === 'top') return <span className={big ? styles.topBig : styles.top}>{translate.top}!</span>
	return <></>
}
