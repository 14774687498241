import React from 'react'
import styles from './Guarantee.module.css'
import { useTranslate } from '../../hooks/useTranslate'
import { Link } from 'react-router-dom'

import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

import MaterialLink from '@material-ui/core/Link'

export default function Guarantee() {
	const translate = useTranslate({
		delivery: { en: 'Fast shipping', ru: 'Быстрая доставка' },
		guarantee: { en: 'Guarantee', ru: 'Гарантии' },
		reviews: { en: 'Reviews', ru: 'Отзывы' },
		support: { en: 'Stable technical support', ru: 'Стабильная техподдержка' },
	})

	return (
		<div className={styles.container}>
			<div className={styles.item}>
				<span className={styles.icon} style={{ color: '#42a5f5' }}>
					<LocalShippingOutlinedIcon />
				</span>
				<MaterialLink component={Link} to="/payment">
					{translate.delivery}
				</MaterialLink>
			</div>
			<div className={styles.item}>
				<span className={styles.icon} style={{ color: '#66bb6a' }}>
					<VerifiedUserOutlinedIcon />
				</span>
				<MaterialLink component={Link} to="/guarantee">
					{translate.guarantee}
				</MaterialLink>
			</div>

			<div className={styles.item}>
				<span className={styles.icon} style={{ color: '#ff7043' }}>
					<SmsOutlinedIcon />
				</span>
				<MaterialLink component={Link} to="/reviews">
					{translate.reviews}
				</MaterialLink>
			</div>

			<div className={styles.item}>
				<span className={styles.icon} style={{ color: '#ffca28' }}>
					<HelpOutlineOutlinedIcon />
				</span>
				{translate.support}
			</div>
		</div>
	)
}
