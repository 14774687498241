import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslate } from '../hooks/useTranslate'
import MaterialLink from '@material-ui/core/Link'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			'& > * + *': {
				marginTop: theme.spacing(2),
			},
		},
	})
)

export default function TransitionAlerts() {
	const classes = useStyles()
	const [open, setOpen] = React.useState(true)
	const translate = useTranslate({
		info: {
			en: 'All reviews were submitted by real buyers through the independent service',
			ru: 'Все отзывы оставлены реальными покупателями через независимый сервис',
		},
	})

	return (
		<div className={classes.root}>
			<Collapse in={open}>
				<Alert
					variant="filled"
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpen(false)
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					{translate.info}{' '}
					<MaterialLink color="inherit" href="https://oplata.info" target="_blank">
						Oplata.info
					</MaterialLink>
				</Alert>
			</Collapse>
		</div>
	)
}
