import React from 'react'
import styles from './Shop/Shop.module.css'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { useHistory } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { useTranslate } from './hooks/useTranslate'

export default function BackButton() {
	const history = useHistory()
	const translate = useTranslate({
		goBack: { en: 'Back', ru: 'Назад' },
	})

	return (
		<Paper className={styles.shadow} style={{ marginBottom: 10, width: '100%', cursor: 'pointer' }} elevation={0}>
			<ListItem
				style={{ backgroundColor: '#ffffff' }}
				onClick={() => {
					history.goBack()
				}}
			>
				<ListItemText
					primary={
						<span style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', color: '#adadad' }}>
							<NavigateBeforeIcon />
							<span className={styles.listPrimary} style={{ color: '#adadad', textTransform: 'uppercase', fontSize: 14, fontWeight: 500 }}>
								{translate.goBack}
							</span>
							<span></span>
						</span>
					}
				/>
			</ListItem>
		</Paper>
	)
}
